<template>
<div class="w-full h-full rounded-lg all flex p-5">
  <div class="w-full md:block flex cursor-pointer items-center" @click="goTo">
    <div class="md:mr-0 mr-3">
      <svg-icon
        :name="icons"
        class="h-12"
        original
      />
    </div>
    <div>
    <div class="sous md:mt-2">{{sousTitle}}</div>

    <div class="nom md:mt-2">
      {{ nom }}
    </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'index',
  props: {
    nom: {
      type: String,
      default: ''
    },
    sousTitle: {
      type: String,
      default: ''
    },
    icons: {
      type: String,
      default: 'smilet'
    }
  },
  methods: {
    goTo () {
      this.$router.push('/' + this.icons)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .nom{
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }
.sous{
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: #909090;
}

.all{
 width: 100%;
  height: 247px;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 20px 20px 0px 0px;
  font-family: $font-default;
}
.all:hover{
  margin-top: -10px;
  background: linear-gradient(180deg, #f1e8e8 0%, rgba(255, 255, 255, 0) 100%)
}

@media screen and (max-width: 700px){
.all{
  height: 106px;
}
  .sous{
    font-size: 21px;
  }
  .nom{
    font-size: 21px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 770px){
  .sous{
    font-size: 21px;
  }
  .nom{
    font-size: 21px;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .sous{
    font-size: 21px;
  }
  .nom{
    font-size: 21px;
  }
}
</style>
