<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto">
        <div
          class="success-container h-full p-6"
        >
          <div class="titre">Finaliser votre souscription</div>
          <div class="flex mt-6 justify-center etapes">
            <div class="w-1/5">
              <steep-line :all-steep="allSteep" :position="2" :index="0" :color="steepColor"/>
            </div>
            <div class="w-1/5">
              <steep-line :all-steep="allSteep" :position="2" :index="1" :color="steepColor"/>
            </div>
            <div class="w-1/5">
              <steep-line :all-steep="allSteep" :position="2" :index="2" :color="steepColor"/>
            </div>
            <div class="w-1/7">
              <steep-line :all-steep="allSteep" :position="3" :index="3" :color="steepColor"/>
            </div>
          </div>

          <div class="flex mt-2 justify-center etapes">
            <div class="w-1/5 text-center steepColor" v-for="(item, index) in allSteep" :key="index">
              {{item}}
            </div>
          </div>

          <div class="bloc flex p-5 mt-5">
            <div class="w-1/7 flex mr-5">
              <svg-icon
                name="auto"
                class="h-12"
                original
                v-if="product.type === 'Auto'"
              />
              <img
                src="../../assets/icons/moto.svg"
                class="h-12 w-12"
                v-if="product.type === 'Moto'"
              />
              <img
                src="../../assets/icons/voyage.svg"
                class="h-12 w-12"
                v-if="product.type === 'Voyage'"
              />
              <img
                src="../../assets/icons/mrh.svg"
                class="h-12 w-12"
                v-if="product.type === 'Mrh'"
              />
<!--              <svg-icon-->
<!--                name="mrh"-->
<!--                class="h-12"-->
<!--                original-->
<!--                v-if="product.type === 'Mrh'"-->
<!--              />-->
            </div>

            <div class="w-6/7">
              <div class="titreCar">{{product.marque}} {{product.models}}</div>
              <div class="titreCar" v-if="product.type === 'Voyage'">{{product.pays}} | {{product.destination}}</div>

              <div class="sousCar mt-2" v-if="product.type === 'Auto'">
                Plaque : {{product.plaque}}
              </div>
              <div class="sousCar mt-2" v-if="product.type === 'Moto'">
                Model : {{product.model}}
              </div>
              <div class="sousCar mt-2" v-if="product.type === 'Mrh'">
                Model : {{product.model.model}}
              </div>

              <div class="sousCar mt-2">
                Devis : {{ product.id }}
              </div>

              <div class="total mt-5">{{ product.devis.computed }} Fr</div>
            </div>
          </div>

          <div class="md:flex mt-6">
            <div class="button after w-1/2 mr-4" @click="fermer">Plus tard </div>
            <div class="button continuer w-1/2" @click="goTo()">Finaliser </div>
            <div class="w-full text-center mt-4 resp" @click="fermer">Plus tard </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import steepLine from '@/components/helper/add/steepLine'

export default {
  name: 'Success',
  components: { PopupBase, steepLine },
  props: {
    wantToMakeOffer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      allSteep: ['Devis', 'Identification', 'Complèter...', 'Paiement'],
      product: null
    }
  },

  mounted () {
    this.product = this.$store.getters.trackFirstDevis

    const iscommercial = this.$store.getters.trackCommercial
    if (iscommercial === false) {
      this.goTo()
    }
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    goTo () {
      let index = 'Automobile'
      if (this.product.type === 'Auto') {
        index = 'automobile'
      }
      if (this.product.type === 'Moto') {
        index = 'moto'
      }
      if (this.product.type === 'Mrh') {
        index = 'mrh'
      }
      if (this.product.type === 'Voyage') {
        index = 'voyage'
      }
      this.$router.push('/' + index)
    },

    yes () {
      this.$emit('oga', true)
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/style/sass/mixin";
    .success{
      z-index: 999;
    }
    .cardinal{
      width: 55%;
    }
    .steepColor{
      color: #909090;
    }
    .bloc{
      background: #F1F1F1;
      border: 1.19412px solid #E8E8E8;
      box-sizing: border-box;
      border-radius: 11.9412px;
    }
    .total{
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      font-family: $font-default;
      color: #00BF53;
    }
    .titreCar{
      font-weight: bold;
      font-size: 21.4941px;
      line-height: 27px;
      font-family: 'Mulish';
      color: #000000;
    }
    .sousCar{
      font-weight: normal;
      font-size: 16.7176px;
      line-height: 21px;
      font-family: 'Mulish';
      color: #000000;
    }
    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: $white;
      padding: 0px ;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    .titre{
      font-weight: bold;
      font-size: 18px;
      line-height: 180%;
      color: #000000;
    }
    .sous-titre{
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #909090;
    }
    .bloc1{
      background: #F5F5F5;
      border-radius: 12px 0px 0px 12px;
    }
    .button{
      background-color: $base-color;
      height: 57.36px;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: $white;
      border: 1px solid #0269AD;
      box-sizing: border-box;
      border-radius: 10px;
    }
    .after{
      border: 1px solid #A9B2C6;
      background-color: white;
      color: #909090;;
    }
    .resp{
      display: none;
    }
    .continuer:hover{
      background-color: $hover-button
    }
    .after:hover{
      border-color: $hover-button
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 20px;
      }
      .bloc1{
        width: 100%;
      }
      .bloc2{
        width: 100%;
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 50px;
        font-size: 16px
      }
      .after{
        display: none;
      }
      .etapes{
        display: none;
      }
      .resp{
        display: block;
        font-size: 16px
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .success-container {
        width: 100%;
      }
      .cardinal{
        width: 70%;
      }
      .titre{
        font-size: 20px;
      }
      .bloc1{
        width: 100%;
      }
      .bloc2{
        width: 100%;
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 50px;
        font-size: 16px
      }
      .etapes{
        display: none;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .success-container {
        width: 100%;
      }
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 20px;
      }
      .bloc1{
        width: 100%;
      }
      .bloc2{
        width: 100%;
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 50px;
        font-size: 16px
      }
      .etapes{
        display: none;
      }
    }

    @media screen and (max-width: 325px){
      .success-container {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }
</style>
