<template>
  <div class="w-full h-full ">
    <div class="w-full flex items-center">
      <svg-icon
        name="valid"
        class="h-10 mr-3"
        original
        v-if="position > index"
      />
      <svg-icon
        name="invalid"
        class="h-10 mr-3"
        original
        v-if="position <= index"
      />
      <div class="w-1/2 mr-3" v-if="allSteep.length - 1 > index">
        <divider height="1px" color="#DDDDDD"/>
      </div>
    </div>
  </div>
</template>

<script>
import divider from '@/components/helper/add/divider'

export default {
  name: 'index',
  components: {
    divider
  },

  props: {
    allSteep: {
      type: Array,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
    position: {
      type: Number,
      default: null
    },
    color: {
      type: String,
      default: null
    }
  },

  data () {
    return {

    }
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.svg-fill{
  fill: #fff;
}
</style>
