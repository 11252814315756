<template>
<div class="about-page w-full h-screen md:pb-0 pb-6">
  <popup-notif :want-to-make-offer="activeNotif" @oga="retourNotif"/>
  <popup-product :want-to-make-offer="activeProduct" @oga="retourProduct" v-if="activeProduct"/>
  <finaliseSouscription :want-to-make-offer="activeFinalise" v-if="activeFinalise" @oga="retourFinalise"/>

  <div class="baniere md:flex items-center p-6">
    <div class="md:w-2/3">
      <div v-if="user !== undefined" class="nom">
        <span v-if="user.fullName.length > 2">{{user.fullName}}</span>
        <span v-if="user.fullName.length < 2">{{user.username.substr(0, 3)}} {{user.username.substr(3, 8)}}</span>
      </div>
      <div class="titre">Mon espace Oremi</div>

      <div class="md:w-4/5 flex justify-center tiret">
        <div class="w-1/5 ml-16">
        <divider height="17px" color="#FFAC33"/>
        </div>
      </div>
    </div>

    <div class="md:flex justify-end md:w-1/3 w-full">
      <div class="button flex md:mt-0 mt-2" @click="estimer">
        <svg-icon
          name="devis"
          class="md:h-10 h-8 mr-3"
          original
        />
        <div class="mr-2 md:w-auto w-4/5 text-left">
          Estimer une prime
        </div>
        <svg-icon
          name="chevron-right"
          class="md:h-6 h-4"
          original
        />
      </div>
    </div>
  </div>

  <div class="flex mt-6">
    <div class="notif md:flex items-center md:w-2/3 w-1/2 md:p-5 p-4 mr-4 cursor-pointer" @click="notif">
      <svg-icon
        name="notification"
        class="md:h-12 md:mr-5 h-10"
        original
      />
      <div class="notif-label md:mt-0 mt-2 pr-4">{{ notification.length }} Notifications non lues</div>
    </div>

    <div class="location md:flex items-center md:w-1/3 w-1/2 md:p-5 p-4">
      <svg-icon
        name="location"
        class="md:h-12 h-10 md:mr-4"
        original
      />

      <div class="md:mr-4 md:w-2/3 w-full md:mt-0 mt-2 cursor-pointer" @click="agence">
        <div class="titre">Carte d’agences</div>
        <div class="sous">Rendez-nous visite</div>
      </div>

      <svg-icon
        name="chevron-right"
        class="h-6 ico"
        original
        color="white"
      />
    </div>
  </div>

  <div class="md:flex justify-center md:mt-12 mt-5">
    <div
      class="md:w-1/3 w-full md:mt-0 md:mr-4 md:mt-0 mt-3"
      data-aos="fade-right"
      data-aos-duration="2000"
    >
      <optionBloc icons="devis" :nom="$store.getters.allDevis.length + ' devis en attente'" sous-title="Devis" class="w-full"/>
    </div>

    <div
      class="md:w-1/3 w-full md:mr-4 md:mt-0 mt-3"
      data-aos="fade-up"
      data-aos-duration="1500"
    >
      <optionBloc icons="souscription" :nom="souscriptions.length + ' Actives'" sous-title="Souscriptions" class="w-full"/>
    </div>

    <div
      class="md:w-1/3 w-full md:mt-0 mt-3 md:mb-0"
      data-aos="fade-left"
      data-aos-duration="1000"
    >
      <optionBloc icons="sinistre" :nom="allSinistre.length + ' Déclarations'" sous-title="Sinistres" class="w-full"/>
    </div>

  </div>
</div>
</template>

<script>
import optionBloc from '@/components/cedro/home/optionBloc'
import divider from '@/components/helper/add/divider'
import finaliseSouscription from '@/components/popup/finaliseSouscription'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'
import popupNotif from '@/components/popup/popupNotif'
import { db } from '@/main'
import popupProduct from '@/components/popup/popupProduct'

export default {
  name: 'index',
  components: {
    optionBloc,
    divider,
    finaliseSouscription,
    popupNotif,
    popupProduct
  },

  data () {
    return {
      activeFinalise: false,
      product: null,
      allDevis: [],
      souscriptions: [],
      activeNotif: false,
      allSinistre: [],
      firebaseId: null,
      notification: [],
      activeProduct: false,
      user: null
    }
  },

  computed: {
    donnee: function () {
      return this.allDevis
    }
  },

  created () {
    this.allDevisSave()

    const iscommercial = this.$store.getters.trackCommercial
    if (iscommercial === false) {
      this.activeFinalise = true
    }
  },

  mounted () {
    this.$store.dispatch('saveConcern', null)
    this.user = this.parseJwt(this.$store.getters.tokenState)

    this.firebaseId = this.parseJwt(this.$store.getters.tokenState).firestoreId
    if (this.parseJwt(this.$store.getters.tokenState).userType === 'ADMIN') {
      this.$router.push('/devs41/admin')
    }

    if (this.parseJwt(this.$store.getters.tokenState).firestoreId === null) {
      window.location.reload()
    }

    setTimeout(() => {
      this.allSouscriptions()
      this.allSinister()
      this.product = this.$store.getters.trackFirstDevis
      if (this.product !== null) {
        this.activeFinalise = true
      }
      this.allDevis = this.$store.getters.allDevis
      if (this.allDevis.length > 0) {
        this.saveDevis()
      }
      this.allNotification()
    }, 2000)
  },

  methods: {
    agence () {
      this.$router.push('/agences')
    },
    retourProduct (answer) {
      this.activeProduct = answer
    },
    estimer () {
      this.activeProduct = true
    },

    allNotification () {
      const tab = []
      db.collection('notifications').doc(this.firebaseId).collection('notifications')
        .get()
        .then((response) => {
          response.forEach((doc) => {
            tab.push(doc.data())
          })

          const nonLu = []
          for (const item in tab) {
            if (tab[item].isRead === false) {
              nonLu.push(tab[item])
            }
          }
          this.notification = nonLu
        })
        .catch((error) => {
          console.log('Error getting documents: ', error)
        })
    },

    parseJwt (token) {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
      // console.log(JSON.parse(jsonPayload))
      return JSON.parse(jsonPayload)
    },

    allSinister () {
      http.get(apiroutes.baseURL + apiroutes.mySinister)
        .then(response => {
          // console.log('sinister')
          // console.log(response.data)
          this.allSinistre = response.data
        })
        .catch(error => {
          console.log('erreur')
          console.log(error)
          if (error.data.statusCode === 401) {
            window.location.reload()
          }
        })
    },

    allDevisSave () {
      http.get(apiroutes.baseURL + apiroutes.getDevis)
        .then(response => {
          // console.log('all Devis from api')
          // console.log(response.data)
          if (response.data.allDevis !== undefined) {
            // console.log(this.$store.getters.allDevis.length)
            if (this.$store.getters.allDevis.length === 0) {
              this.allDevis = response.data.allDevis
              this.$store.dispatch('updateAllDevis', response.data.allDevis)
            }
          } else {
            // console.log('soo')
            this.allDevis = this.$store.getters.allDevis
          }
        })
        .catch(error => {
          console.log('erreur')
          console.log(error)
          if (error.data.statusCode === 401) {
            window.location.reload()
          }
        })
    },

    saveDevis () {
      http.post(apiroutes.baseURL + apiroutes.saveDevis, {
        estimate: {
          allDevis: this.allDevis
        }
      })
        .then(response => {
          // console.log('success Devis from api')
          // console.log(response.data)
        })
        .catch(error => {
          console.log('erreur')
          console.log(error)
          if (error.data.statusCode === 401) {
            window.location.reload()
          }
        })
    },

    retourFinalise (answer) {
      this.activeFinalise = answer
    },

    retourNotif (answer) {
      this.activeNotif = answer
    },
    notif () {
      this.activeNotif = true
    },

    goTo (index) {
      this.$router.push('/' + index)
    },

    allSouscriptions () {
      http.get(apiroutes.baseURL + apiroutes.souscriptions)
        .then(response => {
          this.souscriptions = response.data
        })
        .catch(error => {
          // if (error.status === 401) {
          //   this.$store.dispatch('logout', null)
          //   this.$router.push('/login')
          // }
          console.log('erreur')
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.baniere{
  width: 100%;
  border-radius: 20px;
  height: 159px;
  background: #0269AD;
  color: #FFFFFF;
}
.tiret{
  margin-top: -20px;
}

.notif{
  height: 95px;
  background: #FFFFFF;
  border-radius: 20px;
  .notif-label{
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    font-family: $rubik;
    color: #606060;
  }
}
.notif:hover{
  background-color: #f1e8e8;
}
.location{
  font-family: $font-default;
  background-color: $base-green;
  border-radius: 20px;
  height: 95px;
  .titre {
    font-weight: bold;
    font-size: 18px;
    line-height: 144%;
    letter-spacing: 0.04em;
    color: #FFFFFF;
  }
  .sous{
    font-weight: 500;
    font-size: 18px;
    line-height: 144%;
    letter-spacing: 0.04em;
    color: rgba(255, 255, 255, 0.6);
  }
}
.location:hover{
  background-color: #62a999;
}

.about-page{
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  font-family: $font-default;
}
  .titre{
    position: relative;
    font-weight: 500;
    font-size: 36px;
    line-height: 43px;
    color: #FFFFFF;
    font-family: $rubik
  }
  .num{
    font-weight: bold;
    font-size: 64px;
    line-height: 34px;
    color: rgba(2, 105, 173, 0.1);
  }
  .button{
    height: 71px;
    background: #FFFFFF;
    border-radius: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 144%;
    letter-spacing: 0.04em;
    color: #000000;
    font-family: $font-default;
    border: none;
  }
  .button:hover{
    background-color: #e2fafd;
  }

@media screen and (max-width: 700px){
  .about-page{
    width: 90%;
    height: 100%;
  }
  .baniere{
    height: 182px;
    border-radius: 20px;
  }
  .titre{
    font-size: 27.3375px;
  }
  .button{
    height: 49px;
    width: 100%;
    border-radius: 13.8028px;
    font-size: 14px;
  }
  .notif, .location{
    height: auto !important;
    .notif-label{
      font-size: 16px;
      padding-right: 0px !important;
    }
    .titre{
      font-size: 16px;
    }
    .sous{
      font-size: 14px;
    }
    .ico{
      display: none;
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 770px){
  .about-page{
    width: 90%;
    height: 100%;
  }
  .baniere{
    height: 132px;
    padding: 30px !important;
  }
  .titre{
    font-size: 29.3375px;
  }
  .button{
    height: 52px;
    width: 100%;
    border-radius: 13.8028px;
    font-size: 16px;
  }
  .notif, .location{
    height: 128px;
    .notif-label{
      font-size: 21px;
    }
    .titre{
      font-size: 18px;
    }
    .sous{
      font-size: 16px;
    }
    .ico{
      display: none;
    }
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .about-page{
    width: 90%;
    height: 100%;
  }
  .baniere{
    height: 132px;
    padding: 30px !important;
  }
  .titre{
    font-size: 27.3375px;
  }
  .button{
    height: 49px;
    width: 100%;
    border-radius: 13.8028px;
    font-size: 14px;
  }
  .notif, .location{
    height: 128px;
    .notif-label{
      font-size: 18px;
    }
    .titre{
      font-size: 18px;
    }
    .sous{
      font-size: 16px;
    }
    .ico{
      display: none;
    }
  }
}

@media screen and (max-width: 700px) {
  .nom{
    font-size: 12px;
  }
  .about-page{
    width: 90%;
    height: 100%;
  }
  .baniere{
    height: 182px;
    border-radius: 20px;
    padding: 30px !important;
  }
  .titre{
    font-size: 27.3375px;
  }
  .button{
    height: 49px;
    width: 100%;
    border-radius: 13.8028px;
    font-size: 14px;
    margin-top: 30px !important;
  }
  .notif, .location{
    height: 128px;
    .notif-label{
      font-size: 16px;
    }
    .titre{
      font-size: 16px;
    }
    .sous{
      font-size: 14px;
    }
    .ico{
      display: none;
    }
  }

  @media screen and (max-width: 325px){
    .about-page{
      width: 90%;
      height: 100%;
    }
    .baniere{
      height: 182px;
      border-radius: 20px;
    }
    .titre{
      font-size: 23.3375px;
    }
    .button{
      height: 49px;
      width: 100%;
      border-radius: 13.8028px;
      font-size: 14px;
    }
    .notif, .location{
      height: auto !important;
      .notif-label{
        font-size: 16px;
        padding-right: 0px !important;
      }
      .titre{
        font-size: 16px;
      }
      .sous{
        font-size: 14px;
      }
      .ico{
        display: none;
      }
    }
  }

}
</style>
