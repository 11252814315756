<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal">
        <div
          class="success-container h-full p-5"
        >
          <div class="flex items-center" @click="fermer">
            <svg-icon
              name="back"
              class="h-10 mr-4 cursor-pointer"
              original
            />
            <div class="titre">Notifications</div>
          </div>

          <div class="mt-6">
            <div class="bloc flex p-3 items-center mb-3 cursor-pointer" :class="{'read': item.isRead === true}" v-for="(item, index) in notification" :key="index" @click="selected(item)">
              <svg-icon
                name="notifAlerte"
                v-if="item.data.type !== 'undefined' && item.data.type !== 'successSubscription'"
                class="h-10 mr-4"
                original
              />
              <svg-icon
                name="notifSousc"
                class="h-10 mr-4"
                v-if="item.data.type !== 'undefined' && item.data.type === 'successSubscription'"
                original
              />
              <div class="w-full text-black">
                <div class="sous-titre">{{ item.data.subject }}</div>
                <div class="contenu">{{ item.data.content }}</div>
                <div class="date mt-2">{{ conversion(item.createdAt) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupBase2'
import { db } from '@/main'

export default {
  name: 'Success',
  components: { PopupBase },
  props: {
    wantToMakeOffer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      notification: [],
      firebaseId: null
    }
  },

  mounted () {
    this.firebaseId = this.parseJwt(this.$store.getters.tokenState).firestoreId
    this.allNotification()
  },

  methods: {
    selected (item) {
      if (item.data.type === 'PROPOSITION' || item.data.type === 'SUCCESS_PAID' || item.data.type === 'DOC_MANQUANT') {
        this.$router.push('/sinistre')
      }
      if (item.data.type === 'successSubscription') {
        this.$router.push('/souscription')
      }
      if (item.data.type === 'register') {
        this.$router.push('/')
      }
      this.readNotif(item)
    },

    readNotif (item) {
      db.collection('notifications').doc(this.firebaseId).collection('notifications').doc(item.id)
        .update({
          isRead: true
        })
        .then((response) => {
          // console.log('success read')
          // console.log(response)
        })
        .catch((error) => {
          console.log('Error getting documents: ', error)
        })
    },

    parseJwt (token) {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
      // console.log(JSON.parse(jsonPayload))
      return JSON.parse(jsonPayload)
    },

    fermer () {
      this.$emit('oga', false)
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    },

    conversion (index) {
      return new Date(parseInt(index)).toLocaleString()
    },

    allNotification () {
      const tab = []
      db.collection('notifications').doc(this.firebaseId).collection('notifications').orderBy('createdAt', 'desc')
        .get()
        .then((response) => {
          response.forEach((doc) => {
            const obj = doc.data()
            obj.id = doc.id
            tab.push(obj)
          })

          this.notification = tab
        })
        .catch((error) => {
          console.log('Error getting documents: ', error)
        })
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/style/sass/mixin";
    .success{
      z-index: 999;
    }
    .cardinal{
      width: 100%;
    }
    .svg-icon{
      fill: white;
    }
    .date{
      font-size: 10px;
      font-weight: 300;
    }
    .success-container {
      width: 100%;
      background-color: #F5F5F5;
      padding: 0px;
      height: 100vh;
      border-radius: 0px;
      font-family: $font-default;
      overflow-y: scroll;
    }
    .success-container::-webkit-scrollbar {
      display: none;
    }
    .titre {
      font-weight: 500;
      font-size: 36px;
      line-height: 43px;
      color: black;
    }
    .sous-titre{
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
    }
    .contenu{
      font-weight: 300;
      font-size: 16px;
      line-height: 150%;
    }
    .bloc{
      background: #ffffff;
      border: .4px solid #badcf5;
      box-sizing: border-box;
      border-radius: 10px;
    }
    .read{
      border: none;
      background-color: #e3e7ea;
    }
    .bloc:hover{
     box-shadow: 2px -1px 9px 0px #00000033;
    }

    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
      }
      .titre{
        font-size: 20px;
      }
      .bloc1{
        width: 100%;
      }
      .bloc2{
        width: 100%;
        font-size: 18px;
      }
      .button{
        width: 100%;
      }
    }
</style>
